.deshboardIcon {
  box-shadow: rgba(245, 56, 26, 0.836) 0px 5px 15px;
  border-radius: 10px !important;
}

.card-one {
  background-color: rgb(83, 81, 81) !important;
}

.card-sec {
background-color: rgb(83, 81, 81) !important;
}
.card-three {
  background: linear-gradient(
    180deg,
    rgba(102, 17, 26, 0.8297443977591037) 0%,
    rgba(58, 8, 75, 1) 0%,
    rgba(73, 21, 91, 1) 69%,
    rgba(14, 9, 77, 0.8185399159663865) 100%
  );
}

.bio-user {
  box-shadow: rgba(0, 0, 0, 0.1) 3px 2px 12px; /* background-color: aqua; */
  border-radius: 15px 5px 5px 15px;
}
.img-profile {
  border-radius: 10px;
}

/* ===================Change Password Css===================== */
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}
.modal-items {
  padding: 20px;
  border: none;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  background-color: #ecf0f3;
  border-radius: 15px;
}
.form-field input {
  width: 100%;
  border: none;
  background-color: transparent;
  font-size: 1.1rem;
  color: #666;
  padding: 10px 15px 10px 10px;
}

.form-field {
  padding-left: 10px;
  margin-bottom: 20px;
  border-radius: 20px;
  box-shadow: 1px 1px 2px #cbced1, -13px -13px 20px #fff;
}

.form-field .fa {
  color: #555;
}
.close {
  position: absolute;
  top: 15px;
  right: 20px;
  cursor: pointer;
  font-size: 30px;
}
label {
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: 1.3px;
  padding-left: 10px;
  color: #555;
}

.bTon {
  width: 33%;
  height: 43px;
  padding: 10px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 25px;
  box-shadow: 3px 3px 3px #b1b1b1, -3px -3px 3px #fff;
  transition: all 0.3s ease-in-out;
}
.bTon:hover {
  transform: scale(0.9, 0.9);
  background-color: rgb(49, 50, 50);
}
