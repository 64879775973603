/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap'); */

/* Reseting */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'ubuntu';
}

body {
    background: #ecf0f3;
}

.wrapper {
    max-width: 340px;
    min-height: 400px;
    margin: 60px auto;
    padding: 40px 30px 30px 30px;
    background-color: #ecf0f3;
    border-radius: 15px;
    box-shadow: 13px 13px 20px #cbced1, -13px -13px 20px #fff;
}

.logo {
    width: 80px;
    margin: auto;
    background: linear-gradient(113.72deg, #FFA750 -3.55%, #FF9762 -3.55%, #F32D2E 39.27%, #FF5872 87.62%);
    border-radius: 30px;
}

.logo img {
    width: 100%;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
    box-shadow: 0px 0px 3px #5f5f5f,
        0px 0px 0px 5px #ecf0f3,
        8px 8px 15px #a7aaa7,
        -8px -8px 15px #fff;
        
}

.wrapper .name {
    font-weight: 600;
    font-size: 1.4rem;
    letter-spacing: 1.3px;
    padding-left: 10px;
    color: #555;
}

.wrapper .form-field input {
    width: 100%;
    border: none;
    /* outline: none; */background-color: transparent;
    font-size: 1.1rem;
    color: #666;
    padding: 10px 15px 10px 10px;
}

.wrapper .form-field {
    padding-left: 10px;
    margin-bottom: 20px;
    border-radius: 20px;
  box-shadow: 1px 1px 2px #cbced1, -13px -13px 20px #fff;
}

.wrapper .form-field .fa {
    color: #555;
}

.wrapper .bTn {
    width: 100%;
    height: 40px;
    color: #fff;
    border: none;
    border-radius: 25px;
    background-color: #000;
    box-shadow: 3px 3px 3px #b1b1b1,
        -3px -3px 3px #fff;
    letter-spacing: 1.3px;
    transition: all 0.3s ease-in-out;
}

.wrapper .bTn:hover {
    transform: scale(0.9,0.9);
    background-color: rgb(49, 50, 50)
}

.wrapper a {
    text-decoration: none;
    font-size: 0.8rem;
    color: #121213;
}

.wrapper a:hover {
    color: rgb(18, 18, 19)
}

@media(max-width: 380px) {
    .wrapper {
        margin: 30px 20px;
        padding: 40px 15px 15px 15px;
    }
}